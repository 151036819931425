// screen saver page

import ScreenSaver from "../../components/ScreenSaver";

const Screen = ({
  screenSaverList,
  screensaverActive,
  screensaverVisible,
  screensaverClicked,
  delay,
}) => {

  return (
    <div>
      {screensaverActive && screensaverVisible && screenSaverList?.length && (
        <div
          id="screensaver"
          style={styles.screensaver}
          onClick={screensaverClicked}
        >
          <ScreenSaver items={screenSaverList} delay={delay} />
        </div>
      )}
    </div>
  );
};

const styles = {
  screensaver: {
    position: "fixed",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    // backgroundColor: "rgba(0, 0, 0, 0.90)",
    zIndex: 100,
    // backdropFilter: "blur(14.063rem)",
  },
};

export default Screen;
