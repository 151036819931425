import Home from "./pages/Home";
import Category from "./pages/Gategory";
import { Routes, Route, useNavigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import GlobalStyles from "./components/GlobalStyles";
import KioskUiProvider from "./components/KioskUiProvider";
import theme from "./theme";
import "./index.css";
import MapPage from "./pages/MapPage";
import Documentation from "./pages/Documentation";
import QrCodePage from "./pages/QrCodePage";
import Screen from "./pages/Screen";
import { useCallback, useEffect, useRef, useState } from "react";
import { fetchData } from "./pages/services";

const SCREENSAVER_DELAY_MS = 90000;
const SCREENSAVER_ACTIVE_TIME_MS = 8760 * 3600000;
const SCREENSAVER_INACTIVE_TIME_MS = 0;
const BASE_CDN = "https://concierge.fra1.cdn.digitaloceanspaces.com/";

function App() {
  const queryClient = new QueryClient();

  ////ScreenSaver////
  const [screenSaverList, setScreenSaverList] = useState();
  const [screensaverActive, setScreensaverActive] = useState(true);
  const [screensaverVisible, setScreensaverVisible] = useState(true);
  const [delay, setDelay] = useState();
  const screensaverTimeout = useRef();
  const secondTimer = useRef();
  const navigate = useNavigate();
  const [screenSaverdata, setScreenSaverdata] = useState([]);

  const activeScreensaver = useCallback(() => {
    navigate("/");
    setScreensaverActive(true);
    setScreensaverVisible(true);
    loop();
    function loop() {
      const timerRef = setTimeout(() => {
        setScreensaverVisible(false);
        const timerRef2 = setTimeout(() => {
          setScreensaverVisible(true);
          loop();
        }, SCREENSAVER_INACTIVE_TIME_MS);
        secondTimer.current = timerRef2;
      }, SCREENSAVER_ACTIVE_TIME_MS);
      secondTimer.current = timerRef;
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchData().then((data) => {
      setDelay(data?.screenSaver[0]?.delay);
      setScreenSaverdata(data?.screenSaver);
      reformattedData(data?.screenSaver);
    });
    activeScreensaver();
    navigate("/screensaver");
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData().then((data) => {
        setDelay(data?.screenSaver[0]?.delay);
        setScreenSaverdata(data?.screenSaver);
        reformattedData(data?.screenSaver);
      });
      activeScreensaver();
    }, 7200000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [screenSaverdata]);

  useEffect(() => {
    const interval = setInterval(() => {
      reformattedData(screenSaverdata);
    }, 30000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [screenSaverdata]);

  const reformattedData = async (data) => {
    const now = new Date();
    const screens = await Promise.all(
      data?.filter((item) => {
        if (item?.fromDate && item?.toDate) {
          return (
            new Date(item?.fromDate) <= now && new Date(item?.toDate) >= now
          );
        } else if (item?.fromDate && !item?.toDate) {
          return new Date(item?.fromDate) <= now;
        } else if (!item?.fromDate && item?.toDate) {
          return new Date(item?.toDate) >= now;
        } else {
          return true;
        }
      })
    );

    if (screens?.length === 0) navigate("/");
    else {
      setScreensaverActive(true);
      navigate("/screensaver");
    }
    const media = screens.map((obj) => obj?.media);
    const list = media?.flat().filter(function (element) {
      return element?.mimetype !== "application/pdf";
    });

    setScreenSaverList(
      list?.map((item) => {
        return {
          src: BASE_CDN + item?.filename,
        };
      })
    );
  };

  const screensaverClicked = useCallback(() => {
    setScreensaverActive(false);
    navigate("/");
    startTimeout();
    // eslint-disable-next-line
  }, []);

  const startTimeout = useCallback(() => {
    clearTimeout(screensaverTimeout.current);
    clearTimeout(secondTimer.current);
    const timeout = setTimeout(() => activeScreensaver(), SCREENSAVER_DELAY_MS);
    screensaverTimeout.current = timeout;
    // eslint-disable-next-line
  }, []);

  const appTouched = useCallback((ev) => {
    if (ev.target.id !== "screensaver") {
      startTimeout();
    }
    // eslint-disable-next-line
  }, []);
  ////ScreenSaver////

  useEffect(() => {
    if (screensaverActive) navigate("/screensaver");
    // eslint-disable-next-line
  }, [screensaverActive]);
  
  return (
    <QueryClientProvider client={queryClient}>
      <KioskUiProvider theme={theme}>
        <GlobalStyles />
        <div className="App" onClick={appTouched}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/category/:id" element={<Category />} />
            <Route path="/plan" element={<MapPage />} />
            <Route path="/documentation" element={<Documentation />} />
            <Route path="/pdf/:id" element={<QrCodePage />} />
            <Route
              path="/screensaver"
              element={
                <Screen
                  appTouched={appTouched}
                  screensaverActive={screensaverActive}
                  screensaverVisible={screensaverVisible}
                  screenSaverList={screenSaverList}
                  delay={delay}
                  screensaverClicked={screensaverClicked}
                />
              }
            />
            <Route path="*" element={<Home />} />
          </Routes>
        </div>
      </KioskUiProvider>
    </QueryClientProvider>
  );
}

export default App;
