import { formatAMPM } from "../../pages/services";
import { useEffect, useState } from "react";
import Dropdown from "../Dropdown";
import {
  City,
  DropdownContainer,
  HeaderDay,
  HeaderTime,
  StyledHeader,
  StyledHeaderLogo,
  StyledHeaderText,
  StyledHeaderWeather,
  WeatherWrapper,
} from "./Header.styles";

const Header = ({
  day,
  logo,
  WeatherIcon,
  weather,
  city,
  variant,
  languages,
}) => {
  const [cTime, setTime] = useState(formatAMPM(new Date()));

  //Update Time
  useEffect(() => {
    const id = setInterval(() => {
      setTime(formatAMPM(new Date()));
    }, 1000);
    return () => clearInterval(id);
  }, []);

  return (
    <StyledHeader>
      <StyledHeaderText variant={variant}>
        <HeaderTime>{cTime}</HeaderTime>
        <HeaderDay>{day}</HeaderDay>
      </StyledHeaderText>
      <StyledHeaderLogo>
        {logo && <img src={logo} alt="logo" />}
      </StyledHeaderLogo>
      <StyledHeaderWeather variant={variant}>
        {WeatherIcon && (
          <img
            width={190}
            height={190}
            src={`weatherIcons/${WeatherIcon}.svg`}
            alt="icon"
          />
        )}
        {/* {WeatherIcon && <WeatherIcon />} */}
        <WeatherWrapper>
          {weather}
          <City>{city}</City>
        </WeatherWrapper>
      </StyledHeaderWeather>
      <DropdownContainer variant={variant}>
        {variant === "secondary" && <Dropdown languages={languages} />}
      </DropdownContainer>
    </StyledHeader>
  );
};

export default Header;
