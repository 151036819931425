import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import SwiperCore, { Autoplay } from "swiper";
import { Pagination } from "swiper";
import { Slide } from "./ScreenSaver.styles";
SwiperCore.use([Autoplay]);

const ScreenSaver = ({ items, delay }) => {
  return (
    <Swiper
      modules={[Pagination, Autoplay]}
      slidesPerView={1}
      loop={items?.length > 1 ? true : false}
      autoplay={{
        delay: delay ? delay : 2000,
      }}
      watchOverflow={true}
      style={{ width: "100%", height: "100%", overflow: "visible" }}
    >
      {items?.length > 0 &&
        items.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <Slide src={item.src} />
            </SwiperSlide>
          );
        })}
    </Swiper>
  );
};

export default ScreenSaver;
