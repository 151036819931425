import {
  Bar,
  Lodging,
  Meeting,
  Music,
  Plan,
  Restaurant2,
  Spa,
  Map,
} from "../components/Icons";

export const getIcon = (iconName) => {
  switch (iconName) {
    case "Restaurants":
      return Restaurant2;
    case "Gastronomie":
      return Bar;
    case "Suite Executive":
      return Music;
    case "B-Bar":
      return Plan;
    case "Hébergement":
      return Lodging;
    case "Events":
      return Meeting;
    case "Chambre Deluxe Premium":
      return Spa;
    case "Suite Junior":
      return Map;

    default:
      return ;
  }
};
