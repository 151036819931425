import { useEffect, useState } from "react";
import BackbuttonField from "../../components/BackbuttonField";
import Header from "../../components/Header";
import { CardSection } from "./Gategory.styles";
import Cardv1Map from "../../components/Card_v1Map";
import useData from "../../hooks/useData";
import { useLocation } from "react-router-dom";
import floatHourToDuration, { fetchData, getLocation } from "../services";
import { getImage, getPdfFile } from "../../helpers/utils";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const noImage =
  "https://concierge.fra1.digitaloceanspaces.com/86847967-8f46-4405-b7aa-15e66b638260.png";

const Gategory = () => {
  const [categorieList, setCategorieList] = useState();
  const location = useLocation();
  const fetchedData = useData((state) => state.fetchedData);
  const [locales, setLocales] = useState();
  const [reformattedHeader, setReformattedHeader] = useState({});
  const [reformattedBackbuttonField, setReformattedBackbuttonField] = useState(
    {}
  );
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  useEffect(() => {
    const parent = location.pathname.split("/")[2];
    if (fetchedData && fetchedData.length > 0) {
      const categories = fetchedData?.categories;
      const categorieList = categories?.filter(
        (categorie) => categorie.parent === parent
      );
      setCategorieList(categorieList);
    } else {
      fetchData()
        .then((data) => {
          const categories = data?.categories;
          const categorieList = categories?.filter(
            (categorie) => categorie.parent === parent
          );
          const categorieTitle = categories.find(
            (categorie) => categorie._id === parent
          );
          setCategorieList(categorieList);
          handleReformattedBackbuttonField(categorieTitle, categorieList);
          //Get Locales
          getLocation(data).then(function (res) {
            handleReformattedHeader(res);
            setLocales(res?.locales);
          });
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line
  }, [fetchedData, location]);

  //Reformatte Language
  const reformattedLanguge = locales?.map((data) => {
    return {
      language: data,
    };
  });

  //Reformatte Header
  const handleReformattedHeader = (data) => {
    setReformattedHeader({
      variant: "secondary",
      logo: data?.logo,
    });
  };

  useEffect(() => {
    categorieList && handleReformattedBackbuttonField(categorieList);
    // eslint-disable-next-line
  }, [i18n.language]);
  //Reformatte BackbuttonField
  const handleReformattedBackbuttonField = (categorieTitle, data) => {
    setReformattedBackbuttonField({
      label: "back",
      text:
        data[0]?.dictionary?.name[i18n.language] ||
        categorieTitle.dictionary.name[i18n.language] ||
        categorieTitle.name + " (" + data?.length + ")",
      onClick: () => navigate("/"),
    });
  };

  const openingHours = () => {
    const currentDate = new Date();
    const day = currentDate.getDay();
    return day;
  };

  const Timing = (time) => {
    if (time) {
      let hour = floatHourToDuration(time)?.hours;
      let suffix = hour >= 12 ? "PM" : "AM";
      var hours = ((hour + 11) % 12) + 1;
      return floatHourToDuration(hours)?.text + " " + suffix;
    }
  };

  const reformattedData = categorieList?.map((data) => {
    let day = openingHours();
    let index = data.openingHours?.findIndex((obj) => {
      return obj.day === day;
    });
    return {
      variant: "primary",
      image: getImage(data.media) || noImage,
      title: data.dictionary.name[i18n.language] || data.name,
      status: "opened",
      open: true,
      article: data.dictionary.description[i18n.language] || data?.description,
      time:
        data.openingHours.length > 0
          ? {
              open: Timing(data.openingHours[index]?.startTime),
              close: Timing(data.openingHours[index]?.endTime),
            }
          : null,
      linkLabel: "menu",
      btnlabel: data.tagOne,
      linkFile: getPdfFile(data.media),
    };
  });

  return (
    <>
      {reformattedHeader && (
        <Header languages={reformattedLanguge} {...reformattedHeader} />
      )}
      <BackbuttonField {...reformattedBackbuttonField} />
      <CardSection>
        {reformattedData && <Cardv1Map items={reformattedData} />}
      </CardSection>
    </>
  );
};

export default Gategory;
